
import React, { Suspense } from "react"
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

const DefaultLayout = React.lazy(() => import('./components/layouts/DefaultLayout'))
const LoginLayout = React.lazy(() => import('./components/layouts/LoginLayout/LoginLayout'));

const CertificatesPage = React.lazy(() => import('./components/pages/Certificates/Certificates'))
const ProfilePage = React.lazy(() => import('./components/pages/Profile/Profile'));
const LoginPage = React.lazy(() => import('./components/pages/Login/Login'));
const SigninPage = React.lazy(() => import('./components/pages/Signin/Signin'));
const SignupPage = React.lazy(() => import('./components/pages/Signup/Signup'));
const ForgotPasswordPage = React.lazy(() => import('./components/pages/ForgotPasswordPage/ForgotPasswordPage'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>...Loading</div>}>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route path="main" element={<CertificatesPage />} />
            <Route path="account" element={<ProfilePage />} />
            <Route path="login" element={<LoginLayout />} >
              <Route path="main" element={<LoginPage />} />
              <Route path="signin" element={<SigninPage />}/>
              <Route path="signup" element={<SignupPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
